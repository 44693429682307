import React, { useContext } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext'
import ExternalLink from 'app/src/elements/ExternalLink';
import { Text } from 'app/src/styles';
import { Link, Outlet } from 'app/src/utils/routing';

const Menu = styled(View)`
  ${props => props.isMobile ? `
    gap: 0;
    border-bottom-width: 1px;
    margin-bottom: 20px;
  ` : `
    width: 260px;
    gap: 20px;
  `}
`;

const Category = styled(View)`
`;

const CategoryLabel = ({ to, children }) => {
  return (
    <Link to={to}>
      <Text>{children}</Text>
    </Link>
  );
};

const Item = ({ to, children }) => {
  return (
    <Link to={to} style={{ marginLeft: 10 }}>
      <Text>{children}</Text>
    </Link>
  );
};

const Help = ({ children }) => {
  const { isMobile, currentUser } = useContext(ClientContext);

  return (
    <View style={isMobile ? {} : { flexDirection: 'row' }}>
      <Menu isMobile={isMobile}>
        <Category>
          <CategoryLabel to="/how-to/account">Account</CategoryLabel>
          <Item to="/how-to/account/create">Create Account</Item>
          <Item to="/how-to/account/login-troubleshooting">
            Trouble Logging In?
          </Item>
          <Item to="/how-to/account/two-factor">Using Two-Factor App</Item>
        </Category>

        <Category>
          <CategoryLabel to="/how-to/pre-registration">
            Pre-Registration
          </CategoryLabel>
          <Item to="/how-to/pre-register">Pre-Registering Sales</Item>
          <Item to="/how-to/licensed-operator">
            Preferred Licensed Operator
          </Item>
          <Item to="/how-to/pre-registration/history">
            History &amp; Tag Log
          </Item>
          <Item to="/how-to/pre-registration/edit">Edit Pre-Registration</Item>
          <Item to="/how-to/pre-registration/void">Void Pre-Registration</Item>
        </Category>

        <Category>
          <CategoryLabel to="/how-to/dealers">Dealerships</CategoryLabel>
        </Category>

        <Category>
          <CategoryLabel to="/how-to/users">Users</CategoryLabel>
        </Category>

        <Category>
          <CategoryLabel to="/how-to/faq">FAQs</CategoryLabel>
        </Category>

        {currentUser?.isEmployee && (
          <Category>
            <Text style={{fontWeight: 600}}>Employee Documentation</Text>

            <Item to="/employee-docs/data-checks">Data Checks</Item>

            <ExternalLink
              url="https://docs.google.com/document/d/19bleNCdRwF8wit2sZZOhUUYydjfMV6K-liLDUgyHyoY/edit?usp=sharing"
            >
              <Text style={{ marginLeft: 10 }}>Continuity Document</Text>
            </ExternalLink>
          </Category>
        )}
      </Menu>

      <View style={{ flex: 1 }}>
        <Outlet />
      </View>
    </View>
  );
};

export default Help;
