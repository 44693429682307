import { gql } from '@apollo/client';

import { NOTE_FIELDS } from 'app/src/components/Notes/queries';

const USER_FIELDS_WITH_DEALERS = gql`
  fragment UserFieldsWithDealers on User {
    id
    email
    fullName
    isExpired
    role
    userDealers {
      id
      admin
      dealer { id, name, label }
      nickname
    }
  }
`;

export const USERS = gql`
  ${USER_FIELDS_WITH_DEALERS}
  query Users (
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput!
  ) {
    users (
      first: $limit
      after: $offset
      options: $options
    ) {
      edges {
        cursor
        node {
          ...UserFieldsWithDealers
        }
      }
      totalCount
    }
  }
`;

export const USER = gql`
  ${USER_FIELDS_WITH_DEALERS}
  ${NOTE_FIELDS}
  query User ($id: ID!) {
    user(id: $id) {
      ...UserFieldsWithDealers
      role
      otpType
      confirmedAt
      notes {
        ...NoteFields
      }
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser (
    $email: String!
    $firstName: String!
    $lastName: String!
    $dealerIds: [ID!]!
    $admin: Boolean!
    $copyNicknames: Boolean
  ) {
    inviteUser (
      email: $email
      firstName: $firstName
      lastName: $lastName
      dealerIds: $dealerIds
      admin: $admin
      copyNicknames: $copyNicknames
    ) {
      user { id }
      errors { path, message }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser (
    $id: ID!
    $userDealersAttributes: [UserDealerInput!]!
  ) {
   updateUser (
      id: $id
      userDealersAttributes: $userDealersAttributes
    ) {
      user { id }
      errors { path, message }
    }
  }
`;

export const UPDATE_USER_OTP_TYPE = gql`
  mutation UpdateUserOtpType(
    $id: ID!
    $otpType: String!
  ) {
    updateUserOtpType(
      id: $id
      otpType: $otpType
    ) {
      otpType
    }
  }
`;

export const GENERATE_USER_OTP = gql`
  mutation GenerateUserOtp($id: ID!) {
    generateUserOtp(id: $id) {
      token
    }
  }
`;

export const GENERATE_LOGIN_TOKEN = gql`
  mutation GenerateLoginToken($id: ID!) {
    generateLoginToken(id: $id) {
      token
    }
  }
`;

export const GENERATE_PASSWORD_LINK = gql`
  mutation GeneratePasswordLink($id: ID!) {
    generatePasswordLink(id: $id) {
      link
    }
  }
`;

export const SENDGRID_HISTORY = gql`
  query SendgridHistory($email: String!) {
    sendgridHistory(email: $email) {
      email
      unsubscribedAt
      messages {
        fromEmail
        toEmail
        subject
        status
        opensCount
        clicksCount
        lastEventTime
      }
    }
  }
`;

export const DELETE_SENDGRID_UNSUBSCRIBE = gql`
  mutation DeleteendgridUnsubscribe(
    $email: String!
    $sendCode: Boolean
  ) {
    deleteSendgridUnsubscribe(
      email: $email
      sendCode: $sendCode
    ) {
      errors { path, message }
    }
  }
`;

export const RESET_USER_LAST_ACTIVITY = gql`
  mutation ResetUserLastActivity ($id: ID!) {
    resetUserLastActivity (id: $id) {
      errors { path, message }
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole (
    $id: ID!
    $role: String!
  ) {
    updateUserRole (
      id: $id
      role: $role
    ) {
      errors { path, message }
    }
  }
`;
