import React, { useContext } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import Search from 'app/src/components/Search';
import config from 'app/src/config';
import ClientContext from 'app/src/contexts/ClientContext';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { Link } from 'app/src/utils/routing';

const Container = styled(View)`
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.header};
  height: 52px;
  padding-horizontal: 10px;
  z-index: 1000;
`;

const SiteTitle = styled(Text)`
  color: white;
  font-size: 22px;
`;

const HomeLink = ({ linkToHome, children }) => {
  if (linkToHome) {
    return (<Link to="/">{children}</Link>);
  } else {
    return (<>{children}</>);
  }
};

const Header = ({ linkToHome }) => {
  const { currentUser } = useContext(ClientContext);

  return (
    <Container>
      <HomeLink linkToHome={linkToHome}>
        <SiteTitle>
          OklahomaTempTag.com
          {config.app.isDemo && ' - DEMO'}
        </SiteTitle>
      </HomeLink>

      {(currentUser?.isEmployee || currentUser?.isAgency) && <Search />}
    </Container>
  );
};

export default Header;
