const config = {
  api: {
    baseUri: process.env.REACT_APP_API_URI,
    uri: process.env.REACT_APP_API_URI + '/graphql'
  },
  pti: {
    token: 'pliHLQ3vIDrC8MvDbvNoaBdoL1fNGDuV',
    issueUrl: 'https://preflighttech.com/api/1/issues'
  },
  app: {
    name: 'novsys-react',
    version: process.env.REACT_APP_VERSION,
    isDemo: 'true' === process.env.REACT_APP_IS_DEMO,
  }
};

export default config;
