import { rest } from 'app/src/utils/rest';

const printOverlay = ({ tag, credentials }) => {
  rest({
    path: `pdf/tag/${tag.id}.pdf`,
    options: { headers: { Accept: 'application/pdf' } },
    credentials,
  }).then(response => {
    response.blob().then(blob => {
      window.open(window.URL.createObjectURL(blob));
    });
  });
};

export default printOverlay;
