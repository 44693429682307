import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Pressable, View } from 'react-native';
import { gql, useLazyQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';
import styled from 'styled-components/native';

import { Container, Input, Results } from 'app/src/elements/Autocomplete';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

import { Field, FieldLabel } from './inputs';

const SUGGEST_ADDRESSES = gql`
  query SuggestAddresses (
    $term: String!
    $dealerId: ID
  ) {
    suggestAddresses (
      term: $term
      dealerId: $dealerId
    ) {
      formattedAddress
      street
      city
      state
      zip
      latitude
      longitude
      country
    }
  }
`;

const ResultContainer = styled(Pressable)`
  flex-direction: row;
  column-gap: 10px;
  margin: 5px;
`;

const Label = styled(View)`
`;

const LabelText = styled(Text)`
  font-weight: 600;
`;

const Result = ({ result, handleSelect }) => {
  return (
    <ResultContainer onPress={() => handleSelect(result)}>
      <Label>
        <LabelText>{result.formattedAddress}</LabelText>
      </Label>
    </ResultContainer>
  );
};

const AddressAutocomplete = ({ field, setValue, dealerId }) => {
  const [searching, setSearching] = useState(false);
  const [offsets, setOffsets] = useState({ top: 0, left: 0 });
  const [results, setResults] = useState([]);
  const [selectedStreet, setSelectedStreet] = useState(field.value);
  const [lastSearchAt, setLastSearchAt] = useState(new Date().getTime());

  const [suggestAddresses] = useLazyQuery(SUGGEST_ADDRESSES, {
    onCompleted: ({ suggestAddresses }) => setResults(suggestAddresses)
  });

  const ref = useCallback(element => {
    if (element) {
      setOffsets(element.getBoundingClientRect());
    }
  }, []);

  const handleChange = value => {
    setSelectedStreet(value);
    field.onChange(value);

    if (!searching) { setSearching(true); }

    const now = new Date().getTime();
    const sinceLastSearch = now - lastSearchAt;

    if (value.length > 6 && sinceLastSearch > 700) {
      setLastSearchAt(now);

      suggestAddresses({
        variables: { term: value, dealerId: (dealerId ? dealerId : null) }
      });
    }
  };

  const handleKeyPress = e => {
    if ('Enter' === e.nativeEvent.key) {
      setSearching(false);
    }
  };

  const handleBlur = () => {
    window.setTimeout(() => setSearching(false), 200);
    field.onBlur();
  };

  const handleSelect = result => {
    setSelectedStreet(result.street);
    field.onChange(result.street);
    setValue('city', result.city);
    setValue('state', result.state);
    setValue('zip', result.zip);
    setValue('latitude', result.latitude);
    setValue('longitude', result.longitude);
    setValue('country', result.country);
    setValue('addressVerification', 'verified');
    setSearching(false);
  };

  const styles = {
    maxWidth: 500,
    top: offsets.top + 32,
    left: offsets.left,
  };

  return (
    <Container>
      <div ref={ref} />
      <Input
        {...field}
        value={selectedStreet}
        style={{ marginLeft: 0 }}
        onChangeText={handleChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
      />
      {searching && field.value.length > 0 && results.length > 0 && (
        <Results>
          {results.map(result => (
            <Result
              key={`${result.formattedAddress}`}
              result={result}
              handleSelect={handleSelect}
            />
          ))}
        </Results>
      )}
    </Container>
  );
};

const AddressStreetInput = props => {
  const {
    name, label, hint, control, rules, errors, setValue, fieldIndex, dealerId,
  } = props;

  return (
    <Field style={{ zIndex: 9000 - fieldIndex }}>
      <FieldLabel
        name={name}
        label={label}
        hint={hint}
        errors={errors}
      />
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <AddressAutocomplete
            field={field}
            setValue={setValue}
            dealerId={dealerId}
          />
        )}
      />
    </Field>
  );
};

export default AddressStreetInput;
