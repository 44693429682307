import React, { useContext, useState } from 'react';
import { View, Dimensions, Pressable } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import Svg, { Rect, Text as TextSVG } from 'react-native-svg';

import ClientContext from 'app/src/contexts/ClientContext';
import { delimited } from 'app/src/utils/formatting';

const ByDateChart = ({ data, width, height }) => {
  const { isMobile } = useContext(ClientContext);

  const [tooltip,setTooltip] = useState({
    x: 0, y: 0, visible: false, index: 0
  });

  if (!height) height = 320;

  data = {
    ...data,
    datasets: data.datasets.map(dataset => ({
      ...dataset,
      rgbColor: `rgb(${dataset.color})`,
      color: (opacity = 1) => `rgba(${dataset.color}, ${opacity})`,
    }))
  };

  const decorator = () => {
    if (!tooltip.visible) { return null; }

    const textProps = {
      x: tooltip.x,
      y: tooltip.y + 30,
      fill: 'white',
      fontSize: '16',
      fontFamily: 'sans-serif',
      textAnchor: 'middle',
    }

    let boxTop = tooltip.y + 10;
    const boxHeight = 30 + (data.datasets.length * 20);

    if (boxTop + boxHeight > height) {
      boxTop = boxTop - 20 - boxHeight;
    }

    return (
      <Svg>
        <Rect
          x={tooltip.x - 30}
          y={boxTop}
          width="60"
          height={boxHeight}
          fill="black"
          rx="4"
        />
        <TextSVG {...textProps} y={boxTop + 20}>
          {data.labels[tooltip.index]}
        </TextSVG>

        {data.datasets.map((dataset, index) => (
          <TextSVG
            {...textProps}
            y={boxTop + 40 + index * 20}
            fontWeight="bold"
            fill={dataset.rgbColor}
          >
            {delimited(dataset.data[tooltip.index])}
          </TextSVG>
        ))}
      </Svg>
    );
  };

  const dotContent = ({ x, y, index, indexData: value }) => {
    return (
      <Rect
        x={x - (isMobile ? 4 : 6)}
        y={y - (isMobile ? 4 : 6)}
        width={isMobile ? 8 : 12}
        height={isMobile ? 8 : 12}
        rx="6"
        stroke="#000000"
        strokeWidth="1"
        fill="#ffffff"
        fillOpacity="0"
        onClick={() => {
          const isSamePoint = tooltip.x === x && tooltip.y === y;

          setTooltip(previousState => ({
            x, y, index, visible: (isSamePoint ? !previousState.visible : true),
          }));
        }}
      />
    );
  };

  return (
    <LineChart
      data={data}
      width={width || Dimensions.get('window').width - 40}
      height={height}
      formatYLabel={value => delimited(parseInt(value))}
      verticalLabelRotation={30}
      decorator={decorator}
      withVerticalLabels={height > 200 && !isMobile}
      renderDotContent={dotContent}
      fromZero
      chartConfig={{
        backgroundGradientFrom: '#006400',
        backgroundGradientTo: '#003000',
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        propsForLabels: {
          fontFamily: 'sans-serif',
          fontSize: 14,
        },
        propsForDots: {
          r: (isMobile ? '4' : '6'),
        },
      }}
      style={{
        marginVertical: 8,
        borderRadius: 8,
      }}
    />
  );
};

export default ByDateChart;
