import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Button } from 'app/src/elements/buttons';
import Modal from 'app/src/elements/Modal';
import { Subtitle, Text } from 'app/src/styles';
import { cityStateZip } from 'app/src/utils/address';

const Address = styled(View)`
  marginTop: 10px;
`;

const AddressLine = styled(Text)`
  font-weight: 600;
`;

const AddressErrors = ({ tag, setShow, useAddress }) => {
  return (
    <Modal title="Address Validation" setShow={setShow}>
      <Text>
        The supplied purchaser address could not be verified.
        Please review the address.
      </Text>

      {tag.street.match(/^\d+$/) && (
        <Text style={{ fontStyle: 'italic' }}>
          (Note: For PO Boxes, please enter "PO Box" before the number)
        </Text>
      )}

      <Address>
        <AddressLine>{tag.street}</AddressLine>
        {!!tag.unitNumber && (
          <AddressLine>{tag.unitType} {tag.unitNumber}</AddressLine>
        )}
        <AddressLine>{cityStateZip(tag)}</AddressLine>
      </Address>

      <Button label="Use This Address" wide onPress={useAddress} />
      <Button label="Edit Address" onPress={() => setShow(false)} wide />
    </Modal>
  );
};

export default AddressErrors;
