import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

import { NOTE_FIELDS } from 'app/src/components/Notes/queries';
import { ORDER_CORE_FIELDS } from 'app/src/components/Orders/queries';

export const DEALER_CORE_FIELDS = gql`
  fragment DealerCoreFields on Dealer {
    id
    name
    street
    city
    state
    zip
    phone
    url
    inactiveAt
    approvalStatus
    registrationWarningThreshold
  }
`;

export const DEALER_FIELDS = gql`
  ${DEALER_CORE_FIELDS}
  fragment DealerFields on Dealer {
    ...DealerCoreFields
    activeMember
    defaultLabel
    label
    userNickname
    licenses {
      id
      licenseType
      licenseNumber
      licenseString
      inactiveAt
      value: id
      label: licenseString
    }
  }
`;

export const DEALER = gql`
  ${DEALER_FIELDS}
  ${NOTE_FIELDS}
  ${ORDER_CORE_FIELDS}
  query Dealer($id: ID!) {
    dealer(id: $id) {
      ...DealerFields
      userDealers {
        id
        fullName
        email
        admin
        nickname
        userId
      }
      dealerProducts {
        id
        newLicense {
          id
          licenseString
          inactiveAt
        }
        usedLicense {
          id
          licenseString
          inactiveAt
        }
        product {
          id
          name
        }
        suggestedProduct {
          id
          name
        }
      }
      featureSubscriptions {
        id
        status
        createdAt
        deletedAt
        fullStatus
        dealer { label }
      }
      notes {
        ...NoteFields
      }
      orders {
        ...OrderCoreFields
        product { id, name }
        newLicense { licenseString }
        usedLicense { licenseString }
      }
    }
  }
`;

export const DEALER_FOR_REVIEW = gql`
  ${DEALER_FIELDS}
  query Dealer($id: ID!) {
    dealer(id: $id) {
      ...DealerFields
      userDealers {
        id
        fullName
        email
        admin
      }
      matches {
        ...DealerFields
        userDealers {
          id
          fullName
          email
          admin
        }
      }
    }
  }
`;

export const DEALERS = gql`
  ${DEALER_FIELDS}
  query Dealers (
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput!
  ) {
    dealers (
      first: $limit
      after: $offset
      options: $options
    ) {
      edges {
        cursor
        node {
          ...DealerFields
          lastOrderName
        }
      }
      totalCount
    }
  }
`;

export const DEALERS_NO_RECENT_ORDER = gql`
  ${DEALER_FIELDS}
  query DealersNoRecentOrder {
    dealersNoRecentOrder {
      ...DealerFields
      registeredTagCount
      preRegistrationSubscriptionStatus
    }
  }
`;

export const DEALERS_NON_ADR_PRE_REGISTRATION = gql`
  ${DEALER_FIELDS}
  query DealersNonAdrPreRegistration ($days: Int!) {
    dealersNonAdrPreRegistration (days: $days) {
      ...DealerFields
      registeredTagCount(days: $days)
      nonAdrTagCount(days: $days)
      lastNonAdrTagRegisteredAt
      preRegistrationSubscriptionStatus
    }
  }
`;

const DEALER_ARGUMENTS = `
  $name: String!
  $street: String!
  $city: String!
  $state: String!
  $zip: String!
  $phone: String!
  $url: String
  $licensesAttributes: [LicenseInput!]
`;

const DEALER_VARIABLES = `
  name: $name
  street: $street
  city: $city
  state: $state
  zip: $zip
  phone: $phone
  url: $url
  licensesAttributes: $licensesAttributes
`;

export const CREATE_DEALER = gql`
  ${DEALER_CORE_FIELDS}
  mutation CreateDealer (
    ${DEALER_ARGUMENTS}
    $note: String
  ) {
    createDealer (
      ${DEALER_VARIABLES}
      note: $note
    ) {
      dealer {
        ...DealerCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_DEALER = gql`
  ${DEALER_CORE_FIELDS}
  mutation UpdateDealer (
    $id: ID!
    ${DEALER_ARGUMENTS}
    $registrationWarningThreshold: Int
    $userNickname: String
  ) {
    updateDealer (
      id: $id
      ${DEALER_VARIABLES}
      registrationWarningThreshold: $registrationWarningThreshold
      userNickname: $userNickname
    ) {
      dealer {
        ...DealerCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_DEALER_INACTIVE = gql`
  ${DEALER_CORE_FIELDS}
  mutation UpdateDealerInactive (
    $id: ID!
    $inactive: Boolean!
  ) {
    updateDealerInactive (
      id: $id
      inactive: $inactive
    ) {
      dealer {
        ...DealerCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const REVIEW_DEALER = gql`
  ${DEALER_CORE_FIELDS}
  mutation ReviewDealer (
    $id: ID!
    $mergeIntoId: ID
    $approvalStatus: String!
    $createSampleOrders: Boolean
  ) {
    reviewDealer (
      id: $id
      mergeIntoId: $mergeIntoId
      approvalStatus: $approvalStatus
      createSampleOrders: $createSampleOrders
    ) {
      dealer {
        ...DealerCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const CREATE_LICENSE = gql`
  mutation CreateLicense (
    $dealerId: ID!
    $licenseType: String!
    $licenseNumber: String!
  ) {
    createLicense (
      dealerId: $dealerId
      licenseType: $licenseType
      licenseNumber: $licenseNumber
    ) {
      ${ERRORS}
    }
  }
`;

export const INACTIVATE_LICENSE = gql`
  mutation InactivateLicense ($id: ID!) {
    inactivateLicense (id: $id) {
      ${ERRORS}
    }
  }
`;

export const RESOLVE_DIFFERS_LAST_ORDER_NAME = gql`
  mutation ResolveDiffersLastOrderName (
    $id: ID!
    $name: String!
  ) {
    resolveDiffersLastOrderName (
      id: $id
      name: $name
    ) {
      ${ERRORS}
    }
  }
`;

export const CONNECT_REGISTRATION_CODE = gql`
  mutation ConnectRegistrationCode($code: String!) {
    connectRegistrationCode(code: $code) {
      dealer {
        name
      }
      error
      message
    }
  }
`;

export const CHECK_LICENSE_REGISTRATION = gql`
  query CheckLicenseRegistration(
    $licenseType: String!
    $licenseNumber: String!
  ) {
    checkLicenseRegistration (
      licenseType: $licenseType
      licenseNumber: $licenseNumber
    )
  }
`;

export const SET_DEALER_NICKNAME = gql`
  mutation SetDealerNickname (
    $dealerId: ID!
    $nickname: String
  ) {
    setDealerNickname (
      dealerId: $dealerId
      nickname: $nickname
    ) {
      ${ERRORS}
    }
  }
`;
