import React, { useContext, useEffect, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPenToSquare, faBan } from '@fortawesome/free-solid-svg-icons';

import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import { DateTimePicker } from 'app/src/elements/DateTimePicker';
import { Fields, defaultValues, prepareInput } from 'app/src/elements/forms';
import { Field, FieldLabel } from 'app/src/elements/inputs';
import { Button } from 'app/src/elements/buttons';
import { Text, Title } from 'app/src/styles';
import { dateShort } from 'app/src/utils/formatting';
import { useParams, Link } from 'app/src/utils/routing';

import ByDateChart from './ByDate';
import ByValueChart from './ByValue';
import { LABELED_DATASETS } from './queries';

const CHART_TYPES = [
  { value: 'orders', label: 'Orders' },
  { value: 'pre-registered', label: 'Pre-Registrations', dealer: true },
  { value: 'tags-printed', label: 'Tags Printed' },
  { value: 'sales', label: 'Vehicle Sales', dealer: true },
  { value: 'makes', label: 'Vehicle Makes', dealer: true, bar: true },
  { value: 'years', label: 'Vehicle Years', dealer: true, bar: true},
];

const DEALER_CHART_TYPES = CHART_TYPES.filter(({ dealer }) => dealer);

const BAR_CHART_TYPES = CHART_TYPES
  .filter(({ bar }) => bar)
  .map(({ value }) => value);

const GROUPINGS = [
  { value: 'day', label: 'Day' },
  { value: 'weekday', label: 'Weekday' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
];

const FIELDS = {
  type: {
    label: 'Chart Data',
    select: DEALER_CHART_TYPES,
  },
  grouping: {
    label: 'Group By',
    select: GROUPINGS,
  },
};

const DEFAULT_OPTIONS = {
  type: 'pre-registered',
  grouping: 'day',
  startOn: '',
  endOn: '',
};

const DatePicker = ({ control, name }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DateTimePicker
          {...field}
          type="date"
          mode="date"
        />
      )}
    />
  );
};

const Charts = () => {
  const { currentUser, isMobile, isTablet } = useContext(ClientContext);
  const [customDates, setCustomDates] = useState(false);

  const defaultOptions = { ...DEFAULT_OPTIONS };
  const params = useParams();

  if (params.type) { defaultOptions.type = params.type; }

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      ...defaultValues({ fields: FIELDS, resource: defaultOptions })
    }
  });

  let maxValues = 20;
  if (isMobile) {
    maxValues = 6;
  } else if (isTablet) {
    maxValues = 10;
  }

  const { data, refetch } = useQuery(LABELED_DATASETS, {
    variables: {
      ...defaultOptions,
      startOn: null,
      endOn: null,
      maxValues,
      dealerIds: (params.dealerId ? [params.dealerId] : null),
      dealerId: params.dealerId,
    },
  });

  useEffect(() => {
    if (data) {
      setValue('startOn', data.labeledDatasets.startOn);
      setValue('endOn', data.labeledDatasets.endOn);
    }
  }, [data]);

  if (!data) { return <Loading />; }

  const onSubmit = input => {
    const variables = prepareInput(input, FIELDS);

    if (!customDates || !input.startOn) {
      variables.startOn = null;
    }

    if (!customDates || !input.endOn) {
      variables.endOn = null;
    }

    refetch(variables);
  };

  const type = watch('type');

  const fields = { ...FIELDS };

  if (BAR_CHART_TYPES.includes(type)) {
    fields.grouping = { ...fields.grouping, type: 'hidden' };
  }

  if (currentUser.isEmployee) {
    fields.type = { ...fields.type, select: CHART_TYPES };
  }

  return (
    <View>
      {data?.dealer && (
        <Link to={`/dealers/${data.dealer.id}`} style={{ marginBottom: 16 }}>
          <Title>{data.dealer.label}</Title>
        </Link>
      )}

      <View style={ isMobile ? {} : { flexDirection: 'row', gap: 20 }}>
        <Fields
          fields={fields}
          control={control}
        />

        <Field key="startOn">
          <FieldLabel
            name="startOn"
            label="Dates"
          />

          {customDates ? (
            <View style={{ flexDirection: 'row' }}>
              <DatePicker name="startOn" control={control} />
              <Text>-</Text>
              <DatePicker name="endOn" control={control} />

              <Pressable
                onPress={() => setCustomDates(false)}
                style={{ marginLeft: 6, marginTop: 6 }}
              >
                <FontAwesomeIcon icon={faBan} />
              </Pressable>
            </View>
          ) : (
            <View style={{ flexDirection: 'row', marginTop: 6, gap: 6}}>
              <Text>
                {dateShort(data.labeledDatasets.startOn)}{' - '}
                {dateShort(data.labeledDatasets.endOn)}
              </Text>

              <Pressable onPress={() => setCustomDates(true)}>
                <FontAwesomeIcon icon={faPenToSquare} />
              </Pressable>
            </View>
          )}
        </Field>
      </View>

      <Button label="Update" onPress={handleSubmit(onSubmit)} wide />

      {BAR_CHART_TYPES.includes(data.labeledDatasets.type) ? (
        <ByValueChart data={data.labeledDatasets} allowStacked />
      ) : (
        <ByDateChart data={data.labeledDatasets} />
      )}
    </View>
  );
};

export default Charts;
