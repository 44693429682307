import React, { useContext, useEffect, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

import EmailAndPhoneDoc from 'app/src/components/Docs/Tags/EmailAndPhone';
import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import { useNavigate, useParams } from 'app/src/utils/routing';
import {
  Fields,
  defaultValues,
  prepareInput,
  EMAIL_PATTERN,
  PHONE_PATTERN,
  ZIP_CODE_PATTERN,
} from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import FormErrors from 'app/src/elements/FormErrors';
import { Notice } from 'app/src/elements/Notice';
import Input from 'app/src/elements/inputs';
import { Text, Title, Subtitle, Row, Column } from 'app/src/styles';

import NewFeatureSubscription from 'app/src/components/FeatureSubscriptions/new';
import { DEALER } from 'app/src/components/Dealers/queries';
import { SEARCH } from 'app/src/components/Search/queries';

import AddressErrors from './AddressErrors';
import PreRegistrationErrors from './PreRegistrationErrors';
import {
  REGISTER_TAG,
  SEARCH_UNREGISTERED_SERIALS,
  VIN_DECODE
} from './queries';

const PRINT_OPTIONS = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'none',
    label: 'Pre-printed (just entering purchase data)',
  },
  {
    value: 'data',
    label: 'Print purchase data on pre-printed',
  },
  {
    value: 'blank',
    label: 'Print on blank sheet',
  },
];

const TYPE_OPTIONS = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'A',
    label: 'Automobile',
  },
  {
    value: 'M',
    label: 'Motorcycle',
  },
];

const TAG_FIELDS = {
  dealerId: {
    label: 'Dealer',
    clientSelect: 'activeDealers',
    oneOption: 'no-choice',
    rules: { required: true },
  },
  /* TODO Currently disabled because printing by dealer is not yet supported
  printOption: {
    label: 'Print Options',
    select: PRINT_OPTIONS,
    rules: { required: true },
  },
  */
  serialNumber: {
    label: 'Tag Number',
    rules: {
      required: true,
      maxLength: { value: 8, message: 'Maximum 8 characters' },
    },
  },
  soldOn: {
    label: 'Date Vehicle Sold and Tag Issued',
    type: 'date',
    rules: {
      required: true,
      validate: value => {
        if (value > DateTime.now().toISODate()) {
          return 'Must not be in the future';
        } else if (value < DateTime.now().minus({ days: 60 }).toISODate()) {
          return 'Must not be more than 60 days in the past';
        }
      }
    }
  },
};

export const VEHICLE_FIELDS = {
  vin: {
    label: 'VIN',
    rules: {
      required: true,
      pattern: {
        value: /^\w{17}$|^\w{6}$|^\w{7}$|^\w{8}$|^\w{13}$/,
        message: 'Must be 17 characters'
      },
    }
  },
  year: {
    rules: {
      required: true,
      pattern: { value: /^\d{4}$/, message: 'Must be 4 digits' },
    }
  },
  make: {
    clientSelect: 'makes',
    rules: { required: true },
  },
  model: { rules: { required: true } },
  color: { rules: { required: true } },
  style: { rules: { required: false } },
  type: {
    select: TYPE_OPTIONS,
    hint: 'Automobile or Motorcycle, based on tag size',
    rules: { required: true }
  },
  purchasePrice: {
    type: 'int',
    hint: 'Optional'
  },
  tradeInValue: {
    type: 'int',
    hint: 'Optional'
  },
};

export const PURCHASER_FIELDS = {
  purchaser: {
    label: 'Purchaser Name',
    rules: { required: true }
  },
  street: {
    label: 'Purchaser Street Address or PO Box',
    hint: 'Address the new metal plate will be mailed to',
    rules: { required: true }
  },
  unitType: {
    label: 'Purchaser Unit Type',
    clientSelect: 'unitTypes'
  },
  unitNumber: {
    label: 'Purchaser Unit Number',
  },
  city: {
    label: 'Purchaser City',
    rules: { required: true }
  },
  state: {
    label: 'Purchaser State',
    rules: {
      required: true,
      maxLength: { value: 2, message: 'Use 2-character abbreviation' }
    }
  },
  zip: {
    label: 'Purchaser Zip Code',
    rules: {
      required: true,
      pattern: {
        value: ZIP_CODE_PATTERN,
        message: 'Enter 5-digit zip code or 9 digit zip+4',
      },
    }
  },
  email: {
    label: 'Purchaser Email',
    hint: 'Requested by Service Oklahoma, but not required',
    rules: {
      pattern: { value: EMAIL_PATTERN, message: 'Enter a valid email' },
    },
    docs: {
      title: 'Purhaser Email and Phone',
      element: EmailAndPhoneDoc,
    },
  },
  phone: {
    label: 'Purchaser Phone',
    hint: 'Requested by Service Oklahoma, but not required',
    rules: {
      pattern: {
        value: PHONE_PATTERN,
        message: 'Enter phone number in format ###-###-####'
      },
    },
  },
};

export const FIELDS = {
  ...TAG_FIELDS,
  ...PURCHASER_FIELDS,
  ...VEHICLE_FIELDS,
};

const NOT_REQUIRED_FOR_LOG = [
  'color', 'street', 'city', 'state', 'zip', 'email', 'phone',
];

export const extractFields = (original, modified) => {
  const extracted = {};

  Object.keys(original).forEach(key => extracted[key] = modified[key]);

  return extracted;
};

export const getCountry = ({ term, regions }) => {
  if (!term) return null;
  if (!regions) return null;

  term = term.toUpperCase().trim();

  let match = regions.find(region => region.value === term);
  if (match) { return match.country; }

  return regions.find(region => region.abbreviations.includes(term))?.country
};

/* Make customizations to fields based on other values */
export const customizeFields = ({ fields, values, setValue }) => {
  fields = { ...fields };

  fields.make = {
    ...fields.make,
    autocomplete: {
      clientSelect: 'makes',
      selectedLabel: values.makeLabel || '',
      setSelected: selected => {
        setValue('make', selected?.value);
        setValue('makeLabel', selected?.label);
      }
    }
  };

  fields.unitType = {
    ...fields.unitType,
    rules: {
      validate: value => {
        if (!value && values.unitNumber) {
          return 'If you enter a unit number, you must select a unit type';
        }
      }
    }
  };

  fields.unitNumber = {
    ...fields.unitNumber,
    rules: {
      validate: value => {
        if (!value && values.unitType) {
          return 'If you select a unit type, you must enter a unit number';
        }
      }
    }
  };

  switch (values.country) {
    case 'ca':
      fields.state = {
        ...fields.state,
        label: 'Purchaser State or Province (CA)',
      };

      fields.zip = {
        ...fields.zip,
        label: 'Purchaser Postal Code',
        rules: {
          required: true,
          maxLength: { value: 10, message: 'Maximum 10 characters' },
          pattern: { value: /\w+/ },
        }
      };

      break;
    case 'mx':
      fields.state = {
        ...fields.state,
        label: 'Purchaser State (MX)',
        rules: {
          required: true,
          maxLength: { value: 3, message: 'Use 2 or 3-character abbreviation' },
        }
      };

      fields.zip = {
        ...fields.zip,
        label: 'Purchaser Postal Code',
      };

      break;
  }

  return fields;
};

const NewTag = () => {
  const { currentUser, makes, regions, isMobile } = useContext(ClientContext);
  const navigate = useNavigate();
  const params = useParams();
  const [tagType, setTagType] = useState(params.serialNumber ? 'adr' : null);
  const [vinDecodeError, setVinDecodeError] = useState();
  const [formStatus, setFormStatus] = useState();
  const [addressErrors, setAddressErrors] = useState();
  const [preRegistrationErrors, setPreRegistrationErrors] = useState();
  const [verifiedAddress, setVerifiedAddress] = useState();

  const {
    control, handleSubmit, setValue, watch, formState: { errors }
  } = useForm({
    defaultValues: {
      ...defaultValues({ fields: FIELDS }),
      dealerId: params.dealerId || '',
      serialNumber: params.serialNumber || '',
      type: params.type || '',
      keepTag: 'keep' === tagType,
      printOption: 'none',
      soldOn: DateTime.now().toISODate(),
      country: 'us',
    }
  });

  const values = watch();

  const [getDealerName] = useLazyQuery(DEALER, {
    onCompleted: data => {
      if (!values.dealerName) {
        setValue('dealerName', data.dealer.name);
      }
    }
  });

  useEffect(() => {
    if (params.dealerId) {
      getDealerName({ variables: { id: params.dealerId } });
    }
  }, []);

  useEffect(() => {
    const countryByRegion = getCountry({ term: values.state, regions });

    if (countryByRegion && countryByRegion !== values.country) {
      setValue('country', countryByRegion);
    }
  }, [values]);

  useEffect(() => {
    if (verifiedAddress && values) {
      const checkAddress =
        [values.street, values.city, values.state, values.zip].join('|');

      if (verifiedAddress === checkAddress) {
        if ('verified' !== values.addressVerification) {
          setValue('addressVerification', 'verified');
        }
      } else {
        if ('verified' === values.addressVerification) {
          setValue('addressVerification', 'not_checked');
        }
      }
    }
  }, [values?.street, values?.city, values?.state, values?.zip]);

  useEffect(() => {
    if ('verified' === values?.addressVerification) {
      setVerifiedAddress(
        [values.street, values.city, values.state, values.zip].join('|')
      )
    }
  }, [values.addressVerification]);

  const [decodeVin] = useLazyQuery(VIN_DECODE, {
    onCompleted: ({ vinDecode }) => {
      if (vinDecode) {
        if (vinDecode.error) {
          setVinDecodeError(vinDecode.error);
        } else {
          setVinDecodeError(null);
          setValue('year', vinDecode.year || '');
          setValue('make', vinDecode.make || '');
          setValue('model', vinDecode.model || '');
          setValue('style', vinDecode.style || '');

          if (!values.type) {
            setValue('type', vinDecode.type || '');
          }

          if (vinDecode.make) {
            setValue(
              'makeLabel',
              makes.find(make => make.value === vinDecode.make)?.label
            );
          }
        }
      }
    }
  });

  const [registerTag] = useMutation(REGISTER_TAG, {
    onCompleted: data => {
      if (data.registerTag.tag) {
        navigate('/');
      } else {
        setFormStatus(null);

        if (data.registerTag.errors) {
          setAddressErrors(
            data.registerTag.errors.filter(error => 'address' === error.path[0])
          );

          setPreRegistrationErrors(
            data.registerTag.errors.filter(error => 'address' !== error.path[0])
          );
        }
      }
    }
  });

  useEffect(() => {
    if (17 === values.vin.length) {
      decodeVin({ variables: { vin: values.vin } });
    }
  }, [values.vin]);

  const onSubmit = input => {
    setFormStatus('submitting');
    registerTag({ variables: prepareInput(input, FIELDS) });
  };

  const onSubmitAddressReviewed = input => {
    setFormStatus('submitting');
    input.addressVerification = addressErrors[0].path[1];
    input.lastUnverifiedAddress = addressErrors[0].path[2];
    registerTag({ variables: prepareInput(input, FIELDS) });
  };

  const updateTagType = value => {
    setValue('keepTag', 'keep' === value);
    setTagType(value);
  };

  let fields = { ...FIELDS };

  if (currentUser?.isEmployee) {
    fields.dealerId = { ...fields.dealerId };

    fields.dealerId.autocomplete = {
      query: SEARCH,
      queryName: 'search',
      variables: { type: 'Dealers' },
      selectedLabel: values.dealerName || '',
      setSelected: selected => {
        setValue('dealerId', selected?.id);
        setValue('dealerName', selected?.name);
      }
    };
  }

  if (values.dealerId && 'adr' === tagType) {
    fields.serialNumber = { ...fields.serialNumber };

    fields.serialNumber.autocomplete = {
      componentName: 'serialNumber',
      query: SEARCH_UNREGISTERED_SERIALS,
      queryName: 'searchUnregisteredSerials',
      variables: { dealerId: values.dealerId },
      matchResultAttribute: 'serialNumber',
      selectedLabel: values.serialNumber || '',
      setSelected: selected => {
        setValue('serialNumber', selected?.serialNumber);
        setValue('type', selected?.type);
      },
      updateTagType: updateTagType,
    };
  }

  if ('keep' !== tagType) {
    fields.serialNumber = {
      ...fields.serialNumber,
      rules: {
        required: true,
        minLength: { value: 8, message: 'Must be 8 characters' },
        maxLength: { value: 8, message: 'Must be 8 characters' },
      },
    };
  } else {
    fields.street = {
      ...fields.street,
      hint: 'Address the pre-registration decals will be mailed to',
    };
  }

  if (currentUser?.featureAddressSuggest) {
    fields.street = {
      ...fields.street,
      type: 'addressStreet',
      inputProps: { dealerId: values.dealerId },
    };
  }

  fields = customizeFields({ fields, values, setValue });

  Object.keys(fields).forEach(key => {
    if (!fields[key].rules?.required && !fields[key].hint) {
      fields[key] = { ...fields[key], hint: 'Optional' };
    }
  });

  if (!tagType) {
    return (
      <View>
        <Title>Register Tag</Title>

        <Text>
          Is the customer keeping their metal tag or are you registering a
          10-day temporary tag?
        </Text>

        <Button label="Keep Metal Tag" onPress={() => updateTagType('keep')} />
        <Button label="Temporary Tag" onPress={() => updateTagType('adr')} />
        <Pressable onPress={() => updateTagType('other')}>
          <Text>Pre-Register Temporary Tag not purchased from ADR</Text>
        </Pressable>
      </View>
    );
  }

  return (
    <View>
      <Title>Register Tag</Title>

      {!!values.dealerId && 'other' === tagType && (
        <NewFeatureSubscription dealerId={values.dealerId} />
      )}

      <Fields
        fields={extractFields(TAG_FIELDS, fields)}
        control={control}
        errors={errors}
        setValue={setValue}
      />

      {values.soldOn && (
        <View style={{ flexDirection: 'row', gap: 10 }}>
          <Text>10-Day Tag Expires:</Text>
          <Text style={{ fontWeight: 600 }}>
            {DateTime.fromISO(values.soldOn).plus({ days: 10 }).
              toFormat('LL/dd/yy')
            }
          </Text>
        </View>
      )}

      <Row>
        <Column isMobile={isMobile}>
          <Subtitle>Vehicle Details</Subtitle>

          {vinDecodeError && <Notice type="error">{vinDecodeError}</Notice>}

          <Fields
            fields={extractFields(VEHICLE_FIELDS, fields)}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </Column>

        <Column isMobile={isMobile}>
          <Subtitle>Purchaser Details</Subtitle>

          <Fields
            fields={extractFields(PURCHASER_FIELDS, fields)}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </Column>
      </Row>

      <FormErrors errors={errors} fields={fields} />

      {addressErrors && addressErrors.length > 0 && (
        <AddressErrors
          tag={values}
          setShow={() => setAddressErrors(null)}
          useAddress={handleSubmit(onSubmitAddressReviewed)}
        />
      )}

      {preRegistrationErrors && preRegistrationErrors.length > 0 &&(
        <PreRegistrationErrors
          errors={preRegistrationErrors}
          onVoidCompleted={() => setPreRegistrationErrors(null)}
        />
      )}

      {'submitting' === formStatus ? (
        <Loading />
      ) : (
        <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
      )}
    </View>
  );
};

export default NewTag;
