import React, { useContext, useEffect } from 'react';
import { View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import ClientContext from 'app/src/contexts/ClientContext';
import { Fields, defaultValues, prepareInput } from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import { Title } from 'app/src/styles';
import { useNavigate, useParams } from 'app/src/utils/routing';

import { DEALER, UPDATE_DEALER } from './queries';
import { FIELDS } from './new';
import LicenseInput from './LicenseInput';

const EditDealer = ({ id, setShow, refetch }) => {
  if (!id) { id = useParams().id; }
  const { currentUser } = useContext(ClientContext);
  const navigate = useNavigate();

  const { data } = useQuery(DEALER, { variables: { id } });

  const fields = { ...FIELDS };

  if (currentUser.isEmployee) {
    fields.registrationWarningThreshold = {
      label: 'Registration Warning Threshold',
      type: 'Int',
      hint: (
        'High Registration data check uses this to warn of unusual ' +
        'pre-registration activity'
      ),
      rules: { required: true },
    };
  } else {
    fields.userNickname = {
      label: 'Nickname',
      hint: 'Optional, used in labels and dropdowns',
      rules: {
        maxLength: { value: 100, message: 'Max 100 characters' }
      },
    };
  }

  const {
    control, handleSubmit, reset, watch, formState: { errors }
  } = useForm({
    defaultValues: defaultValues({ fields })
  });

  useEffect(() => {
    if (data) {
      const values = defaultValues({ fields, resource: data.dealer });
      reset(values);
    }
  }, [data]);

  const [updateDealer] = useMutation(UPDATE_DEALER, {
    onCompleted: () => {
      if (setShow) {
        setShow(false);
        refetch();
      } else {
        navigate('/dealers')
      }
    }
  });

  const onSubmit = input => {
    updateDealer({ variables: prepareInput(input, fields) });
  };

  const licensesAttributes = watch('licensesAttributes');

  return (
    <View>
      <Title>Edit Dealer: {data?.dealer.name}</Title>

      <Fields fields={fields} control={control} errors={errors} />

      <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
    </View>
  );
};

export default EditDealer;
