import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import { StyledPicker } from 'app/src/elements/inputs';
import { Picker } from 'app/src/elements/Picker';
import { Text } from 'app/src/styles';

import { UPDATE_USER_ROLE } from './queries';

const ROLES = [
  { value: 'dealer', label: 'Dealer' },
  { value: 'employee', label: 'Employee' },
  { value: 'admin', label: 'Admin Employee' },
  { value: 'agency', label: 'State Agency User' },
];

const Role = ({ user, refetch }) => {
  const { currentUser } = useContext(ClientContext);
  const [role, setRole] = useState(user.role);

  const [updateUserRole] = useMutation(UPDATE_USER_ROLE, {
    onCompleted: () => refetch(),
  });

  const handleSubmit = () => {
    updateUserRole({ variables: { id: user.id, role } });
  };

  const canChangeRole = 'admin' === currentUser?.role;

  return (
    <View style={{ flexDirection: 'row', gap: 6, alignItems: 'baseline' }}>
      <Text>Role:</Text>

      {canChangeRole ? (
        <>
          <StyledPicker
            selectedValue={role}
            onValueChange={setRole}
          >
            {ROLES.map(item => (
              <Picker.Item key={item.value} {...item} />
            ))}
          </StyledPicker>

          {canChangeRole && role !== user.role && (
            <Button dangerous label="Change" onPress={handleSubmit} />
          )}
        </>
      ) : (
        <Text style={{ fontWeight: 600 }}>{user.role.toUpperCase()}</Text>
      )}

      {'dealer' === user.role && 0 === user.userDealers.length && (
        <Text>No associated dealers</Text>
      )}
    </View>
  );
};

export default Role;
