import React, { useState } from 'react';
import { View, Dimensions, Pressable } from 'react-native';
import { BarChart, StackedBarChart } from 'react-native-chart-kit';
import Svg, { Rect, Text as TextSVG } from 'react-native-svg';

const ByValueChart = ({ data, width, height, allowStacked }) => {
  const [tooltip,setTooltip] = useState({
    x: 0, y: 0, visible: false, index: 0
  });

  if (!height) height = 320;

  const stacked = allowStacked && data.datasets.length > 1;

  data = {
    ...data,
    data: data.datasets[0].data.map((_value, index) => (
      data.datasets.map(dataset => dataset.data[index])
    )),
    datasets: [{
      data: data.datasets[0].data.map((_value, index) => (
        data.datasets.map(dataset => dataset.data[index])
          .reduce((sum, value) => sum + value, 0)
      ))
    }],
    barColors: ['#000000', '#404040', '#808080']
  };

  const Chart = stacked ? StackedBarChart : BarChart;

  return (
    <Chart
      data={data}
      width={width || Dimensions.get('window').width - 40}
      height={height}
      verticalLabelRotation={30}
      withVerticalLabels={height > 200}
      withHorizontalLabels={!stacked}
      fromZero
      chartConfig={{
        backgroundGradientFrom: '#006400',
        backgroundGradientTo: '#003000',
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        propsForLabels: {
          fontFamily: 'sans-serif',
          fontSize: 14,
        },
      }}
      style={{
        marginVertical: 8,
        borderRadius: 8,
      }}
    />
  );
};

export default ByValueChart;
