import React from 'react';
import { View } from 'react-native';
import { Subtitle, Text } from 'app/src/styles';

import Video from 'app/src/elements/Video';

import { Step } from '../elements';

const CreateAccount = () => {
  return (
    <View>
      <Subtitle>Creating an Account</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          If you have ordered tags from AutoDealersResource.com, your order will
          include an information sheet with a custom sign up URL. You can
          follow that URL to expedite the review process, but it is not
          required.
        </Text>

        <Text>
          See this video or the details below for more information.
        </Text>

        <Video
          url="https://www.youtube.com/embed/OMfOLt5n1oE?si=-0siZWEb2mf_MI_t"
          title="oklahomatemptag.com account registration"
        />

        <Text>
          There are two ways to setup an account:
        </Text>

        <Text style={{ fontWeight: 600 }}>
          Using a unique registration website address that came with your tag
          order.
        </Text>

        <Text>
          If you've recently received a tag order printed by Automotive
          Dealers Resource, there will be a sheet along with your order summary
          and tag log with a uniqute registration web address. To use that
          address:
        </Text>

        <View>
          <Step number={1}>
            Type that registration website address into your browser's address
            bar.
          </Step>

          <Step number={2}>
            You'll be shown the dealer name to verify.
          </Step>

          <Step number={3}>
            Enter your email on this page, then click "Next".
          </Step>

          <Step number={4}>
            If you've already created an account using that email address for
            another dealership, you will be prompted to enter your password.
            Otherwise, enter your first and last name, and click "Register".
          </Step>

          <Step number={5}>
            Look for an email within a few minutes with a link to confirm your
            email address and verify your registration. Once you click on
            that link in your email, you'll be prompted to create a password.
          </Step>

          <Step number={6}>
            Once you create your password, you will then be logged in and
            can begin pre-registering vehicle sales.
          </Step>
        </View>

        <Text style={{ fontWeight: 600 }}>
          If you don't have a registration web address, you can still
          establish an account by providing dealership information:
        </Text>

        <View>
          <Step number={1}>
            On the home screen of OklahomaTempTag.com, enter your email in
            the "Login or Register" form, then click "Next".
          </Step>

          <Step number={2}>
            Enter your first and last name, and click "Register".
          </Step>

          <Step number={3}>
            You'll receive an email within a few minutes with a link to
            confirm your email address and verify your registration.
            Follow that link to set your password.
          </Step>

          <Step number={4}>
            You will then be prompted to submit your dealership information.
          </Step>

          <Step number={5}>
            You must include at least one license number, but can
            include two (for example, if the dealership has new and used
            dealer license numbers). Choose the type of license and then
            enter the number in the box to the right. You do not need to
            include the prefix (such as "UD") or the year suffix, if given one.
          </Step>

          <Step number={6}>
            Enter the other dealership details and click the submit button
            at the bottom.
          </Step>

          <Step number={7}>
            OklahomaTempTag.com will review the dealership information
            within one business day. Once your account has been reviewed,
            you will be notified by email that you can now pre-register sales.
          </Step>
        </View>
      </View>
    </View>
  );
};

export default CreateAccount;
