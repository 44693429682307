import { DateTime } from 'luxon';

export const dollars = amount => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  return formatter.format(amount);
};

export const currency = value => {
  if (!value) { return ''; }
  if ('undefined' !== typeof value.value) { value = value.value };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
};

export const delimited = value => {
  if (!value && 0 !== value && '0' !== value) { return ''; }
  if ('undefined' !== typeof value.value) { value = value.value };

  const formatter = new Intl.NumberFormat('en-US', {});

  return formatter.format(value);
}

export const dateShort = value => {
  if (!value) { return ''; }
  if ('undefined' !== typeof value.value) { value = value.value };

  return DateTime.fromISO(value).
    toLocaleString(DateTime.DATE_SHORT);
};

export const datetimeShort = value => {
  if (!value) { return ''; }
  if ('undefined' !== typeof value.value) { value = value.value };

  return DateTime.fromISO(value).
    toLocaleString(DateTime.DATETIME_SHORT);
};

export const datetimeMedium = value => {
  if (!value) { return ''; }
  if ('undefined' !== typeof value.value) { value = value.value };

  return DateTime.fromISO(value).
    toLocaleString(DateTime.DATETIME_MED);
};
