import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import Modal from 'app/src/elements/Modal';
import { Text } from 'app/src/styles';

import { CLOSE_CURRENT_USER_ACCOUNT } from './queries';

const CloseAccount = ({ setShow }) => {
  const { currentUser, logout } = useContext(ClientContext);
  const [formStatus, setFormStatus] = useState();

  const [closeCurrentUserAccount] = useMutation(CLOSE_CURRENT_USER_ACCOUNT, {
    onCompleted: () => logout()
  });

  return (
    <Modal title="Close Account" setShow={setShow}>
      {currentUser?.activeFeatureSubscriptionsCount ? (
        <Text style={{ fontWeight: 600 }}>
          Your account is currently associated with 1 or more dealers that
          have subscriptions. Closing your account will not cancel these
          subscriptions. To manage subscriptions, go to "Subscriptions" under
          the "Account" menu.
        </Text>
      ) : (
        null
      )}

      <Button
        label="YES, CLOSE ACCOUNT"
        onPress={closeCurrentUserAccount}
        wide
        dangerous
      />

      <Button label="No, keep my account" onPress={() => setShow(false)} wide/>
    </Modal>
  );
};

export default CloseAccount;
