import React from 'react';
import { View } from 'react-native';
import { useQuery } from '@apollo/client';

import { Text, Subtitle, TitleLinks } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import ByDateChart from 'app/src/components/Charts/ByDate';
import ByValueChart from 'app/src/components/Charts/ByValue';

import { DEALER_DASHBOARD } from './queries';

const DealerDashboard = () => {
  const { data } = useQuery(DEALER_DASHBOARD, { fetchPolicy: 'no-cache' });

  if (!data) { return null; }

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'baseline',
          justifyContent: 'space-between'
        }}
      >
        <Subtitle>Vehicles Sold - Past 30 Days</Subtitle>

        <TitleLinks>
          <Link to="/charts/">
            <Text>⤇ More Charts</Text>
          </Link>
        </TitleLinks>
      </View>

      <ByDateChart data={data.sales} />
    </View>
  );
};

export default DealerDashboard;
