import { gql } from '@apollo/client';

import { LABELED_DATASETS_FIELDS } from 'app/src/components/Charts/queries';

const DASHBOARD_METRIC_FIELDS = gql`
  fragment DashboardMetricFields on DashboardMetric {
    label
    days
    businessDays
    count
    minTime
    maxTime
    percent
    percentLabel
  }
`;

export const DASHBOARD = gql`
  ${DASHBOARD_METRIC_FIELDS}
  ${LABELED_DATASETS_FIELDS}
  query Dashboard (
    $startOn: ISO8601Date
  ) {
    dashboard {
      orders {
        ...DashboardMetricFields
      }
      tags {
        ...DashboardMetricFields
      }
      registrations {
        ...DashboardMetricFields
      }
      users {
        ...DashboardMetricFields
      }
    }
    orders: labeledDatasets (
      type: "orders"
      grouping: "day"
      startOn: $startOn
    ) {
      ...LabeledDatasetsFields
    }
    tags: labeledDatasets (
      type: "tags-printed"
      grouping: "day"
      startOn: $startOn
    ) {
      ...LabeledDatasetsFields
    }
    registrations: labeledDatasets (
      type: "pre-registered"
      grouping: "day"
      startOn: $startOn
    ) {
      ...LabeledDatasetsFields
    }
  }
`;

export const DEALER_DASHBOARD = gql`
  ${LABELED_DATASETS_FIELDS}
  query DealerDashboard {
    sales: labeledDatasets (type: "sales") {
      ...LabeledDatasetsFields
    }
  }
`;
