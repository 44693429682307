import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import ClientContext from 'app/src/contexts/ClientContext';
import { Fields, defaultValues, EMAIL_PATTERN } from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import { Title } from 'app/src/styles';
import { Notice } from 'app/src/elements/Notice';

import CloseAccount from './CloseAccount';
import { UPDATE_CURRENT_USER } from './queries';

const FIELDS = {
  email: {
    rules: {
      required: true,
      pattern: { value: EMAIL_PATTERN, message: 'Enter a valid email' },
    },
  },
  firstName: { rules: { required: true } },
  lastName: { rules: { required: true } },
};

const Profile = () => {
  const { currentUser } = useContext(ClientContext);
  const [formStatus, setFormStatus] = useState();
  const [showCloseAccount, setShowCloseAccount] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: defaultValues({ fields: FIELDS, resource: currentUser  })
  });

  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: () => setFormStatus('submitted')
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    updateCurrentUser({ variables: input });
  };

  return (
    <View>
      <Title>Update Your Profile</Title>

      {'submitted' === formStatus && (
        <Notice type="success">
          Your profile has been updated.
        </Notice>
      )}

      <Fields fields={FIELDS} control={control} errors={errors} />

      <Button label="Save" onPress={handleSubmit(onSubmit)} wide />

      <View
        style={{
          marginTop: 60,
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          label="Close Account"
          onPress={() => setShowCloseAccount(true)}
          dangerous
        />
      </View>

      {showCloseAccount && (
        <CloseAccount setShow={setShowCloseAccount} />
      )}
    </View>
  );
};

export default Profile;
