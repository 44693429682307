import React, { useContext, useState } from 'react';
import { View, Pressable, Switch } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCirclePlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import ClientContext from 'app/src/contexts/ClientContext';
import mobileTable from 'app/src/elements/mobileTable';
import RelayTable from 'app/src/elements/RelayTable';
import { Title, TitleLinks, Text, Setting, SettingLabel } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { Actions, Action, ActionText } from 'app/src/styles/table';
import { Link } from 'app/src/utils/routing';

import MobileRow from './MobileRow';
import { DEALERS } from './queries';
import Nickname from './Nickname';
import Review from './Review';

const columns = [
  {
    key: 'name',
    label: 'Name',
    content: ({ entry, value }) => {
      if ('Pending' === entry.approvalStatus) {
        return (
          <>
            <Text>{value}</Text>
            <Text style={{ fontWeight: 600 }}> PENDING REVIEW</Text>
          </>
        );
      } else {
        return value;
      }
    }
  },
  {
    key: 'licenses',
    label: 'License #s',
    sort: 'prevent',
    value: ({ value }) => value.map(license => (
      `${license.licenseType}${license.licenseNumber}`
    )).join(', '),
  },
  {
    key: 'street',
    label: 'Street',
  },
  {
    key: 'city',
    label: 'City',
  },
  {
    key: 'userNickname',
    label: 'Nickname',
    hidden: true,
    sort: 'prevent',
  },
  {
    key: 'functions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <Link to={`/dealers/${entry.id}`}>
        <ActionText>View</ActionText>
      </Link>
    )
  },
];

const Dealers = ({ approvalStatus }) => {
  const defaultScopes = 'Pending' === approvalStatus ? ['pending'] : ['active']

  const { currentUser, isMobile, hasPolicy } = useContext(ClientContext);
  const [showEditNickname, setShowEditNickname] = useState();
  const [showReview, setShowReview] = useState();
  const [refetch, setRefetch] = useState();
  const [scopes, setScopes] = useState(defaultScopes);

  let displayColumns = columns;

  if ('Pending' === approvalStatus) {
    const actionColumn = { ...displayColumns[displayColumns.length - 1] };

    actionColumn.content = ({ entry }) => (
      <Actions>
        <Action first>
          <Link to={`/dealers/${entry.id}/edit`}>
            <ActionText>Edit</ActionText>
          </Link>
        </Action>

        <Action>
          <Pressable onPress={() => setShowReview(entry)}>
            <ActionText>Review</ActionText>
          </Pressable>
        </Action>
      </Actions>
    );

    displayColumns = [...columns];
    displayColumns[displayColumns.length - 1] = actionColumn;
  } else if (currentUser?.isEmployee) {
    const actionColumn = { ...displayColumns[displayColumns.length - 1] };

    actionColumn.content = ({ entry }) => (
      <Actions>
        <Action first>
          <Link to={`/dealers/${entry.id}`}>
            <ActionText>View</ActionText>
          </Link>
        </Action>

        <Action>
          <Link to={`/dealers/${entry.id}/edit`}>
            <ActionText>Edit</ActionText>
          </Link>
        </Action>
      </Actions>
    );

    displayColumns = [...columns];
    displayColumns[displayColumns.length - 1] = actionColumn;
  } else if (currentUser.isDealer) {
    displayColumns = [...columns];

    displayColumns[displayColumns.length - 2] = {
      ...displayColumns[displayColumns.length - 2],
      hidden: false,
      content: ({ value, entry }) => (
        <View style={{ flexDirection: 'row', gap: 6, alignItems: 'center' }}>
          <Text style={{ fontSize: 14 }}>{value}</Text>
          <Pressable onPress={() => setShowEditNickname(entry)}>
            <FontAwesomeIcon icon={faPenToSquare} color="green" size={16} />
          </Pressable>
        </View>
      )
    };

    if (isMobile) {
      const actionColumn = { ...displayColumns[displayColumns.length - 1] };

      actionColumn.content = ({ entry }) => (
        <Actions>
          <Action first>
            <Link to={`/dealers/${entry.id}`}>
              <ActionText>View</ActionText>
            </Link>
          </Action>

          <Action>
            <Pressable onPress={() => setShowEditNickname(entry)}>
              <ActionText>Set Nickname</ActionText>
            </Pressable>
          </Action>
        </Actions>
      );

      displayColumns[displayColumns.length - 1] = actionColumn;
    }
  }

  return (
    <View>
      <Title>Dealerships</Title>

      {currentUser?.isEmployee ? (
        <View
          style={
            isMobile ? { flexDirection: 'column-reverse' } :
            { flexDirection: 'row', justifyContent: 'space-between' }
          }
        >
          <Setting>
            {!approvalStatus && (
              <>
                <SettingLabel>Include Inactive?</SettingLabel>
                <Switch
                  value={!scopes.includes('active')}
                  onValueChange={value => setScopes(value ? [] : ['active'])}
                  activeTrackColor={colors.lightButton}
                  activeThumbColor={colors.button}
                />
              </>
            )}
          </Setting>

          <TitleLinks isMobile={isMobile} style={{ justifySelf: 'right' }}>
            <Link to="/dealers/no-recent-order">
              <Text>⤇ No Recent Order</Text>
            </Link>

            <Link to="/dealers/non-adr-pre-registrations">
              <Text>⤇ Non-ADR Pre-Registrations</Text>
            </Link>

            <Link to="/dealers/name-differs">
              <Text>⤇ Name Differs</Text>
            </Link>
          </TitleLinks>
        </View>
      ) : (
        hasPolicy({ type: 'Dealer', action: 'create' }) && (
          <Link to="/dealers/new">
            <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
              <FontAwesomeIcon icon={faCirclePlus} color="green" size={24} />
              <Text>Add Dealer</Text>
            </View>
          </Link>
        )
      )}

      <RelayTable
        query={DEALERS}
        queryName="dealers"
        queryVariables={{ scopes }}
        columns={displayColumns}
        setRefetch={setRefetch}
        component={isMobile ? mobileTable : null}
        componentProps={{ rowComponent: MobileRow, currentUser }}
      />

      {showEditNickname && (
        <Nickname
          dealer={showEditNickname}
          setShow={setShowEditNickname}
          refetch={refetch}
        />
      )}

      {showReview && (
        <Review
          dealer={showReview}
          setShow={setShowReview}
          refetch={refetch}
        />
      )}
    </View>
  );
};

export default Dealers;
