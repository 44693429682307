import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

export const TAG_CORE_FIELDS = gql`
  fragment TagCoreFields on Tag {
    id
    keepTag
    serialNumber
    vin
    soldOn
    year
    make
    makeLabel
    model
    color
    style
    type
    purchasePrice
    tradeInValue
    purchaser
    licenseState
    licenseNumber
    street
    unitType
    unitNumber
    city
    state
    zip
    latitude
    longitude
    country
    email
    phone
    printOption
    registeredAt
    submittedAt
    voidAt
    voidSubmittedAt
    voidReason
    canEdit
    canPrintOverlay
    canVoid
    canUnvoid
    canReregister
  }
`;

export const TAG_FIELDS = gql`
  ${TAG_CORE_FIELDS}
  fragment TagFields on Tag {
    ...TagCoreFields
    registeredByName
    dealer {
      id
      name
      label
    }
  }
`;

export const TAG = gql`
  ${TAG_FIELDS}
  query Tag($id: ID!) {
    tag(id: $id) {
      ...TagFields
    }
  }
`;

export const TAGS = gql`
  ${TAG_FIELDS}
  query Tags (
    $dealerId: ID
    $orderId: ID
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput
  ) {
    tags(
      first: $limit
      after: $offset
      dealerId: $dealerId
      orderId: $orderId
      options: $options
    ) {
      edges {
        node {
          ...TagFields
        }
      }
      totalCount
    }
    order: optionalOrder (id: $orderId) {
      id
      label
      dealer { id, label }
    }
    dealer: optionalDealer (id: $dealerId) {
      id
      label
    }
    orders (
      first: 100
      after: "${window.btoa('0')}"
      dealerId: $dealerId
      options: {
        scopes: ["filled"]
      }
    ) {
      edges {
        node {
          id
          label
          dealer { id }
        }
      }
    }
  }
`;

const TAG_ARGUMENTS = `
  $vin: String!
  $year: String!
  $make: String!
  $model: String!
  $color: String!
  $style: String
  $type: String!
  $purchasePrice: Int
  $tradeInValue: Int
  $purchaser: String!
  $licenseState: String
  $licenseNumber: String
  $street: String!
  $city: String!
  $unitType: String
  $unitNumber: String
  $state: String!
  $zip: String!
  $latitude: Float
  $longitude: Float
  $country: String
  $phone: String
  $email: String
`;

const TAG_VARIABLES = `
  vin: $vin
  year: $year
  make: $make
  model: $model
  color: $color
  style: $style
  type: $type
  purchaser: $purchaser
  purchasePrice: $purchasePrice
  tradeInValue: $tradeInValue
  licenseState: $licenseState
  licenseNumber: $licenseNumber
  street: $street
  city: $city
  state: $state
  unitType: $unitType
  unitNumber: $unitNumber
  zip: $zip
  latitude: $latitude
  longitude: $longitude
  country: $country
  phone: $phone
  email: $email
`;

export const REGISTER_TAG = gql`
  ${TAG_CORE_FIELDS}
  mutation RegisterTag (
    $dealerId: ID!
    $keepTag: Boolean!
    $serialNumber: String!
    $soldOn: String!
    $printOption: String!
    $addressVerification: String
    $lastUnverifiedAddress: String
    ${TAG_ARGUMENTS}
  ) {
    registerTag (
      dealerId: $dealerId
      keepTag: $keepTag
      serialNumber: $serialNumber
      soldOn: $soldOn
      printOption: $printOption
      addressVerification: $addressVerification
      lastUnverifiedAddress: $lastUnverifiedAddress
      ${TAG_VARIABLES}
    ) {
      tag {
        ...TagCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_TAG = gql`
  ${TAG_CORE_FIELDS}
  mutation UpdateTag (
    $id: ID!
    ${TAG_ARGUMENTS}
  ) {
    updateTag (
      id: $id
      ${TAG_VARIABLES}
    ) {
      tag {
        ...TagCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_SERIAL_NUMBER = gql`
  ${TAG_CORE_FIELDS}
  mutation UpdateSerialNumber (
    $id: ID!
    $serialNumber: String!
  ) {
    updateSerialNumber (
      id: $id
      serialNumber: $serialNumber
    ) {
      tag {
        ...TagCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const CLEAR_TAG_DATA = gql`
  ${TAG_CORE_FIELDS}
  mutation ClearTagData ($id: ID!) {
    clearTagData (id: $id) {
      tag {
        ...TagCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const VOID_TAG = gql`
  ${TAG_CORE_FIELDS}
  mutation VoidTag (
    $id: ID!
    $reason: String
  ) {
    voidTag (
      id: $id
      reason: $reason
    ) {
      tag {
        ...TagCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const UNVOID_TAG = gql`
  ${TAG_CORE_FIELDS}
  mutation UnvoidTag (
    $id: ID!
  ) {
    unvoidTag (
      id: $id
    ) {
      tag {
        ...TagCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const SEARCH_UNREGISTERED_SERIALS = gql`
  query SearchUnregisteredSerials(
    $term: String!
    $dealerId: ID!
  ) {
    searchUnregisteredSerials(
      term: $term
      dealerId: $dealerId
    ) {
      id
      serialNumber
      registeredAt
      voidAt
      type
    }
  }
`;

export const VIN_DECODE = gql`
  query VinDecode($vin: String!) {
    vinDecode(vin: $vin) {
      year
      make
      model
      style
      type
      error
    }
  }
`;
