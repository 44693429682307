import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';

import DocsCard from 'app/src/components/Docs/Card';
import UsersDoc from 'app/src/components/Docs/Users';
import ClientContext from 'app/src/contexts/ClientContext';
import mobileTable from 'app/src/elements/mobileTable';
import Modal from 'app/src/elements/Modal';
import RelayTable from 'app/src/elements/RelayTable';
import { Title } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';

import NewUser from './new';
import EditUser from './edit';
import ExpiredModal from './ExpiredModal';
import MobileRow from './MobileRow';
import User from './show';
import { USERS } from './queries';

const Users = () => {
  const [showNew, setShowNew] = useState();
  const [showEdit, setShowEdit] = useState();
  const [showUser, setShowUser] = useState();
  const [showExpiredModal, setShowExpiredModal] = useState();
  const [refetch, setRefetch] = useState();
  const { currentUser, isMobile } = useContext(ClientContext);

  const columns = [
    {
      key: 'fullName',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
      width: 200,
    },
  ];

  if (currentUser.isEmployee) {
    columns.push({
      key: 'role',
      label: 'Role',
      value: ({ value }) => value.charAt(0).toUpperCase() + value.slice(1),
    });

    columns.push({
      key: 'userDealers',
      label: 'Dealers',
      value: ({ value }) => (
        value.map(userDealer => userDealer.dealer.name).join(', ')
      )
    });

    columns.push({
      key: 'adminActions',
      label: 'Admin Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <View style={{ flexDirection: 'row', gap: 10 }}>
          <Pressable onPress={() => setShowUser(entry)}>
            <ActionText>Details</ActionText>
          </Pressable>
        </View>
      )
    });
  } else {
    currentUser.dealers.map(dealer => {
      columns.push({
        key: dealer.id,
        label: (currentUser.dealers.length > 1 ? dealer.label : 'Role'),
        sort: 'prevent',
        headerStyle: { maxWidth: 200 },
        value: ({ entry }) => {
          const userDealer = entry.userDealers.find(user => (
            user.dealer.id === dealer.id
          ));

          if (!userDealer) { return ''; }
          if (userDealer.admin) { return 'Admin'; }
          return 'Normal User';
        }
      });
    });
  }

  const canAdd =
    currentUser.userDealers.filter(userDealer => userDealer.admin).length > 0

  if (canAdd) {
    columns.push({
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <View style={{ flexDirection: 'row', gap: 10 }}>
          {entry.isExpired ? (
            <Pressable onPress={() => setShowExpiredModal(entry)}>
              <ActionText>Account Expired</ActionText>
            </Pressable>
          ) : (
            <Pressable onPress={() => setShowEdit(entry)}>
              <ActionText>Edit</ActionText>
            </Pressable>
          )}
        </View>
      )
    });
  }

  return (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Title style={{ flex: 1 }}>Users</Title>
        <DocsCard title="User Administration" element={UsersDoc} />
      </View>

      {canAdd && (
        <Pressable onPress={() => setShowNew(true)}>
          <ActionText>Add User</ActionText>
        </Pressable>
      )}

      <RelayTable
        query={USERS}
        queryName="users"
        columns={columns}
        setRefetch={setRefetch}
        component={isMobile ? mobileTable : null}
        componentProps={{ rowComponent: MobileRow }}
      />

      {showNew && <NewUser setShow={setShowNew} refetch={refetch} />}

      {showEdit && (
        <EditUser setShow={setShowEdit} refetch={refetch} user={showEdit} />
      )}

      {showUser && (
        <Modal title={showUser.email} setShow={setShowUser}>
          <User setShow={setShowUser} id={showUser.id} />
        </Modal>
      )}

      {showExpiredModal && (
        <Modal title={showExpiredModal.email} setShow={setShowExpiredModal}>
          <ExpiredModal
            user={showExpiredModal}
            onCompleted={() => { setShowExpiredModal(false); refetch(); }}
          />
        </Modal>
      )}
    </View>
  );
};

export default Users;
