import React, { useState } from 'react';
import { View, Pressable } from 'react-native';

import Ticket from 'app/src/components/Ticket';
import ExternalLink from 'app/src/elements/ExternalLink';
import { Subtitle, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

const QUESTIONS = [
  {
    question: 'What if I entered the wrong tag number?',
    answer: `
      You can edit the tag number if it has not been submitted to the state,
      or void and re-enter.
    `,
    moreInfo: '/how-to/pre-registration/edit',
  },
  {
    question: 'What if the sale is canceled?',
    answer: `
      You should void the pre-registration of any sale that is canceled. Not
      doing so could result in Service Oklahoma rejecting a subsequent
      pre-registration of the same vehicle.
    `,
    moreInfo: '/how-to/pre-registration/void',
  },
  {
    question: 'How do I add other users?',
    answer: `
      If you have Admin access to a dealership, you can add other users by
      going to Account -> Users, then click the (+) button
    `,
    moreInfo: '/how-to/users',
  },
  {
    question: 'Can I access more than one dealer?',
    answer: `
      Yes. An admin access of another dealer can give you access, or you can
      setup another dealership.
    `,
    moreInfo: '/how-to/dealers',
  },
  {
    question: 'Can I change the labels for dealers?',
    answer: `
      Yes, you can set nicknames for dealerships by clicking "Dealerships"
      in the top menu, then clicking the edit button on the nickname column.
    `,
    moreInfo: '/how-to/dealers',
  },
  {
    question: 'Can I do pre-registrations on a phone or tablet?',
    answer: `
      Yes. OklahomaTempTag is designed to be responsive to the size of your
      screen. If you find a screen or form that is hard to use on mobile,
      let us know.
    `,
    feedback: true,
  },
  {
    question: 'How quickly should my customer receive a metal plate?',
    answer: `
      You customer should receive their metal plate or stickers within 10 days,
      but it is usually less than a week. However, OklahomaTempTag.com is
      only responsible for submitting information to Service Oklahoma.
    `,
    moreInfo: '/how-to/pre-registration/sok',
  },
  {
    question:
      "What do I do if my customer hasn't received a metal plate or stickers?",
    answer: `
      Check the customer address on OklahomaTempTag.com and, if needed, void
      and resubmit the pre-registration. Otherwise, you will need to contact
      Service Oklahoma. They may also request you void and re-submit.
    `,
    moreInfo: '/how-to/pre-registration/sok',
  },
  {
    question: (
      'Can I choose a Licensed Operator (tag agent) to handle ' +
      'pre-registrations?'
    ),
    answer: `
      Yes, but not through OklahomaTempTag.com. If you choose a preferred
      License Operator through your okcars account, that will also be the
      default Licensed Operator for pre-registrations submitted through
      OklahomaTempTag.com
    `,
    moreInfo: '/how-to/licensed-operator',
  },
  {
    question: (
      'Can I use OklahomaTempTag.com to pre-register temporary tags I ' +
      'purchased from another vendor?'
    ),
    answer: `
      Yes, although you will have to purchase a service subscription.
    `,
    moreInfo: '/how-to/pre-registration/non-adr',
  },
  {
    question: (
      'How do I create a shortcut to OklahomaTempTag.com on my Desktop or ' +
      'Home Screen?'
    ),
    answer: `
      This is possible but depends on the operating system and browser.
      Searching in your browser for "Create shortcut to website" is likeliest
      to get you a good tutorial
    `,
    external: 'https://www.hellotech.com/guide/for/how-to-create-a-desktop-shortcut-to-a-website',
  }
];

const FAQ = () => {
  const [showTicket, setShowTicket] = useState(false);

  return (
    <View>
      <Subtitle>Frequently Asked Questions</Subtitle>

      {QUESTIONS.map(({ question, answer, moreInfo, external, feedback }) => (
        <View key={question} style={{ marginBottom: 12 }}>
          <Text style={{ fontWeight: 600 }}>{question}{' '}</Text>
          <Text>{' '}{answer.replaceAll(/\s+/g, ' ')}</Text>

          {moreInfo && (
            <Link to={moreInfo}><Text>{'  '}[More Info]</Text></Link>
          )}

          {external && (
            <ExternalLink url={external}>
              <Text>{'  '}[More Info - External Site]</Text>
            </ExternalLink>
          )}

          {feedback && (
            <Pressable onPress={() => setShowTicket(true)}>
              <Text>{'  '}[Provide Feedback]</Text>
            </Pressable>
          )}
        </View>
      ))}

      {showTicket && (
        <Ticket setShow={setShowTicket} />
      )}
    </View>
  );
};

export default FAQ;
