import { gql } from '@apollo/client';

export const LABELED_DATASETS_FIELDS = gql`
  fragment LabeledDatasetsFields on LabeledDatasets {
    labels
    legend
    startOn
    endOn
    type
    datasets {
      color
      data
    }
  }
`;

export const LABELED_DATASETS = gql`
  ${LABELED_DATASETS_FIELDS}
  query LabeledDatasets (
    $type: String!
    $grouping: String
    $startOn: ISO8601Date
    $endOn: ISO8601Date
    $dealerIds: [ID!]
    $maxValues: Int
    $dealerId: ID
  ) {
    labeledDatasets (
      type: $type
      grouping: $grouping
      startOn: $startOn
      endOn: $endOn
      dealerIds: $dealerIds
      maxValues: $maxValues
    ) {
      ...LabeledDatasetsFields
    }
    dealer: optionalDealer (id: $dealerId) {
      id
      label
    }
  }
`;
