import React from 'react';
import { Routes, Route } from 'app/src/utils/routing';

import Docs from './index';
import All from './All';

import Account from './Account';
import CreateAccount from './Account/Create';
import LoginTroubleshooting from './Account/LoginTroubleshooting';
import TwoFactor from './Account/TwoFactor';

import Tags from './Tags';
import PreRegisterSale from './Tags/PreRegisterSale';
import LicensedOperator from './Tags/LicensedOperator';
import NonAdr from './Tags/NonAdr';
import ServiceOklahoma from './Tags/ServiceOklahoma';
import PreRegistrationHistory from './Tags/History';
import EditPreRegistration from './Tags/EditPreRegistration';
import VoidPreRegistration from './Tags/VoidPreRegistration';

import Dealerships from './Dealerships';
import DealershipNicknames from './Dealerships/Nicknames';

import Users from './Users';

import FAQ from './FAQ';

const DocRoutes = ({ prefix }) => {
  return (
    <Routes>
      <Route path={prefix} element={<Docs />}>
        <Route index element={<All />} />
        <Route path="account" element={<Account />} />
        <Route path="account/create" element={<CreateAccount />} />
        <Route
          path="account/login-troubleshooting"
          element={<LoginTroubleshooting />}
        />
        <Route path="account/two-factor" element={<TwoFactor />} />

        <Route path="pre-registration">
          <Route index element={<Tags />} />
          <Route path="sok" element={<ServiceOklahoma />} />
          <Route path="non-adr" element={<NonAdr />} />
          <Route path="history" element={<PreRegistrationHistory />} />
          <Route path="edit" element={<EditPreRegistration />} />
          <Route path="void" element={<VoidPreRegistration />} />
        </Route>

        <Route path="pre-register" element={<PreRegisterSale />} />
        <Route path="licensed-operator" element={<LicensedOperator />} />

        <Route path="dealers">
          <Route index element={<Dealerships />} />
          <Route path="nicknames" element={<DealershipNicknames />} />
        </Route>

        <Route path="users" element={<Users />} />

        <Route path="faq" element={<FAQ />} />
      </Route>
    </Routes>
  );
};

export default DocRoutes;
