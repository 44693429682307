import React from 'react';
import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';

import DataSubmissions from 'app/src/components/DataSubmissions';
import { Title, Text } from 'app/src/styles';
import { useRest } from 'app/src/utils/rest';

const FORMATS = [
  ['json', 'application/json'],
  ['xml', 'application/xml'],
  ['csv', 'text/csv'],
];

const handleDownload = (download, format) => {
  const options = { headers: { Accept: format[1] } };
  const filename = `tags.${format[0]}`;

  download({ options, filename, path: filename });
};

const Item = styled(View)`
  margin-top: 10px;
  width: 100px;
`;

const Label = styled(Text)`
  font-weight: 600;
`;

const Export = () => {
  const { download } = useRest();

  return (
    <View>
      <Title>Export Unsubmitted Pre-Registrations</Title>

      <Text>Choose a format to download</Text>

      {FORMATS.map(format => (
        <Item key={format[0]}>
          <Pressable onPress={() => handleDownload(download, format)}>
            <Label>{format[0].toUpperCase()}</Label>
          </Pressable>
        </Item>
      ))}

      <View style={{ height: 20 }}></View>

      <DataSubmissions />
    </View>
  );
};

export default Export;
