import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Text, Subtitle } from 'app/src/styles';

import { Step } from '../elements';

const DATA_CHECKS_DOCUMENTATION = gql`
  query DataChecksDocumentation {
    dataChecksDocumentation {
      name
      documentation
    }
  }
`;

const Menu = styled(View)`
  ${props => props.isMobile ? `
    gap: 0;
    border-bottom-width: 1px;
    margin-bottom: 20px;
  ` : `
    width: 360px;
    gap: 6px;
  `}
`;

const DataChecksDocs = () => {
  const { isMobile } = useContext(ClientContext);

  const [selectedName, setSelectedName] = useState();

  const { data } = useQuery(DATA_CHECKS_DOCUMENTATION);

  const selectedDataCheck = (data && selectedName) ? (
      data.dataChecksDocumentation
        .find(dataCheck => dataCheck.name === selectedName)
    ) : null;

  return (
    <View style={isMobile ? {} : { flexDirection: 'row' }}>
      <Menu isMobile={isMobile}>
        {data?.dataChecksDocumentation.map(dataCheck => (
          <Pressable
            key={dataCheck.name}
            onPress={() => setSelectedName(dataCheck.name)}
          >
            <Text>{dataCheck.name}</Text>
          </Pressable>
        ))}
      </Menu>

      <View style={{ flex: 1 }}>
        {selectedDataCheck ? (
          <>
            <Subtitle>{selectedDataCheck.name}</Subtitle>
            <Text>{selectedDataCheck.documentation}</Text>
          </>
        ) : (
          <View style={{ gap: 10 }}>
            <Text>
              There are quite a few automated data checks that run every night
              (and some that run every hour or based on events). When a data
              check detects a something, it posts to the November Systems slack,
              two either the tag-data or the tag-alert channel.
            </Text>

            <Step>
              Posts to tag-alert imply something needs to be done. The links in
              the menu of this page can take you to more information with
              details of what to do.
            </Step>

            <Step>
              Posts to tag-data can usually be ignored. They could indicate a
              problem if they show something unexpected or have a high
              frequency and that's generally why Jared has added them.
            </Step>
          </View>
        )}
      </View>
    </View>
  );
};

export default DataChecksDocs;
